import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';

const rootElement = document.getElementById('root');

const scrollToSection = (sectionId) => {
  const section = document.getElementById(sectionId);
  if (section) {
    section.scrollIntoView({ behavior: 'smooth' });
  }
};

const handleNavClick = (event) => {
  const targetId = event.target.getAttribute('href').slice(1);
  scrollToSection(targetId);
  event.preventDefault();
};

document.querySelectorAll('nav ul li a').forEach(anchor => {
  anchor.addEventListener('click', handleNavClick);
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  rootElement
);
