import React from 'react';
import './Technologies.css';
import { FaReact, FaJava, FaDatabase, FaHtml5, FaCss3Alt, FaGithub } from 'react-icons/fa';
import { SiAnaconda, SiMysql } from 'react-icons/si';

const technologies = [
  { name: 'React', icon: <FaReact />, link: 'https://reactjs.org/docs/getting-started.html' },
  { name: 'Java', icon: <FaJava />, link: 'https://docs.oracle.com/javase/8/docs/' },
  { name: 'SQL', icon: <FaDatabase />, link: 'https://dev.mysql.com/doc/' },
  { name: 'HTML', icon: <FaHtml5 />, link: 'https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/HTML5' },
  { name: 'CSS', icon: <FaCss3Alt />, link: 'https://developer.mozilla.org/en-US/docs/Web/CSS' },
  { name: 'GitHub', icon: <FaGithub />, link: 'https://docs.github.com/en' },
  { name: 'Anaconda', icon: <SiAnaconda />, link: 'https://docs.anaconda.com/' },
];

const Technologies = () => {
  return (
    <section id="technologies">
      <h2>Tecnologias</h2>
      <div className="technologies-container">
        {technologies.map((tech, index) => (
          <a href={tech.link} target="_blank" rel="noopener noreferrer" key={index} className="technology">
            <div className="icon">{tech.icon}</div>
            <p>{tech.name}</p>
          </a>
        ))}
      </div>
    </section>
  );
};

export default Technologies;
