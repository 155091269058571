import React from 'react';
import { FaLinkedin, FaGithub } from 'react-icons/fa';
import './About.css';

const About = () => {
  return (
    <section id="about" className="container">
      <h2>Sobre Mim</h2>
      <p>
        Sou um desenvolvedor <strong>fullstack</strong> com formação em <strong>Análise e Desenvolvimento de Sistemas</strong>. 
        Tenho experiência em <strong>React</strong>, <strong>Java (Android)</strong>, <strong>Anaconda</strong>, e outras tecnologias. 
        Apaixonado por criar soluções inovadoras, possuo habilidades em <strong>gerenciamento de ERP</strong> e <strong>manipulação de dados</strong>. 
        Minhas principais competências incluem <strong>desenvolvimento frontend e backend</strong>, <strong>JavaScript</strong>, <strong>HTML</strong>, <strong>CSS</strong>, 
        <strong>Python</strong> e <strong>SQL</strong>, além de <strong>trabalho em equipe</strong>, <strong>resolução de problemas</strong>, 
        <strong>comunicação</strong>, <strong>proatividade</strong> e <strong>adaptabilidade</strong>. 
        Estou sempre aprendendo e buscando oportunidades para aplicar minhas habilidades técnicas e contribuir para o sucesso de projetos inovadores.
      </p>
      <p>
        Confira meu <a href="https://www.linkedin.com/in/bernardo-netto/" target="_blank" rel="noopener noreferrer"><FaLinkedin /> LinkedIn</a> e <a href="https://github.com/sl4k3r1" target="_blank" rel="noopener noreferrer"><FaGithub /> GitHub</a>.
      </p>
    </section>
  );
};

export default About;
