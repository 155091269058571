import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import { FaLinkedin, FaGithub, FaWhatsapp } from 'react-icons/fa';
import './Contact.css';

const Contact = () => {
  const [state, handleSubmit] = useForm("xzzpgbbw");
  if (state.succeeded) {
    return <p>Mensagem enviada com sucesso!</p>;
  }

  return (
    <section id="contact">
      <h2>Contato</h2>
      <div className="contact-container">
        <form className="contact-form" onSubmit={handleSubmit}>
          <label htmlFor="name">Nome</label>
          <input
            id="name"
            type="text"
            name="name"
            required
          />
          <ValidationError 
            prefix="Nome" 
            field="name"
            errors={state.errors}
          />

          <label htmlFor="email">Email</label>
          <input
            id="email"
            type="email" 
            name="email"
            required
          />
          <ValidationError 
            prefix="Email" 
            field="email"
            errors={state.errors}
          />

          <label htmlFor="message">Mensagem</label>
          <textarea
            id="message"
            name="message"
            required
          />
          <ValidationError 
            prefix="Message" 
            field="message"
            errors={state.errors}
          />

          <button type="submit" disabled={state.submitting}>
            Enviar
          </button>
        </form>
        <div className="contact-info">
          <h3>Links de contato</h3>
          <div className="social-links">
            <a href="https://www.linkedin.com/in/bernardo-netto/" target="_blank" rel="noopener noreferrer">
              <FaLinkedin /> LinkedIn
            </a>
            <a href="https://github.com/sl4k3r1" target="_blank" rel="noopener noreferrer">
              <FaGithub /> GitHub
            </a>
            <a href="https://wa.me/5551995620737" target="_blank" rel="noopener noreferrer">
              <FaWhatsapp /> WhatsApp
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
