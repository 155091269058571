import React, { useState, useEffect } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Typewriter } from 'react-simple-typewriter';
import './Header.css';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [showMenuIcon, setShowMenuIcon] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleScroll = () => {
    const header = document.querySelector('header');
    const headerHeight = header.offsetHeight;
    setIsScrolled(window.scrollY > 50);
    setShowMenuIcon(window.scrollY > headerHeight);
  };

  const handleLinkClick = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <header className={isScrolled ? 'scrolled' : ''}>
        <div className="header-content">
          <h1>
            <Typewriter
              words={['Bernardo Netto']}
              cursor
              cursorStyle='_'
              typeSpeed={180}
              deleteSpeed={50}
              delaySpeed={1000}
            />
          </h1>
          <p>
            <Typewriter
              words={[
                'Fullstack Developer',
                'React Developer',
                'Java (Android) Developer',
                'IT Analyst',
                'Backend Developer',
                'Frontend Developer'
              ]}
              loop={true}
              cursor
              cursorStyle='|'
              typeSpeed={80}
              deleteSpeed={10}
              delaySpeed={450}
            />
          </p>
        </div>
      </header>
      <button className={`menu-icon ${showMenuIcon ? 'show' : ''}`} onClick={toggleMenu}>
        {isOpen ? <FaTimes /> : <FaBars />}
      </button>
      <nav className={`nav-menu ${isOpen ? 'open' : ''}`}>
        <ul>
          <li><a href="#about" onClick={handleLinkClick}>Sobre Mim</a></li>
          <li><a href="#technologies" onClick={handleLinkClick}>Tecnologias</a></li>
          <li><a href="#projects" onClick={handleLinkClick}>Projetos</a></li>
          <li><a href="#contact" onClick={handleLinkClick}>Contato</a></li>
        </ul>
      </nav>
      <div className={`overlay ${isOpen ? 'active' : ''}`} onClick={toggleMenu}></div>
    </>
  );
};

export default Header;
