import React from 'react';
import { FaGithub, FaGlobe } from 'react-icons/fa';
import { Typewriter } from 'react-simple-typewriter';
import './Projects.css';

const projects = [
  {
    title: 'Calculadora React',
    description: 'Uma calculadora simples para aprender gerenciamento de estado e manipulação de eventos em React.',
    imageUrl: 'https://github.com/sl4k3r1/sl4k3r1/blob/main/Captura%20de%20tela%202024-07-25%20110423.png?raw=true',
    githubUrl: 'https://github.com/sl4k3r1/calculator',
    liveUrl: 'https://calculator-react-bernardo.vercel.app/',
  },
  {
    title: 'Galeria de Fotos',
    description: 'Um projeto de galeria de fotos desenvolvido em React.',
    imageUrl: 'https://github.com/sl4k3r1/sl4k3r1/blob/main/Captura%20de%20tela%202024-08-06%20095547.png?raw=true', // Substitua por uma URL real
    githubUrl: 'https://github.com/sl4k3r1/photo-gallery',
    liveUrl: 'https://photo-gallery-bernardo-react.vercel.app/',
  },
  {
    title: 'Lista de Compras',
    description: 'Um aplicativo simples de lista de compras em React.',
    imageUrl: 'https://github.com/sl4k3r1/sl4k3r1/blob/main/Captura%20de%20tela%202024-08-06%20100822.png?raw=true', // Substitua por uma URL real
    githubUrl: 'https://github.com/sl4k3r1/shopping-list',
    liveUrl: 'https://shopping-list-bernardo-react.vercel.app/',
  }
];

const Projects = () => {
  return (
    <section id="projects">
      <h2>Projetos</h2>
      <div className="projects-container">
        {projects.map((project, index) => (
          <div className="project-card" key={index}>
            <img src={project.imageUrl} alt={project.title} />
            <h3>{project.title}</h3>
            <p>{project.description}</p>
            <div className="project-links">
              <a href={project.githubUrl} target="_blank" rel="noopener noreferrer">
                <FaGithub /> GitHub
              </a>
              <a href={project.liveUrl} target="_blank" rel="noopener noreferrer">
                <FaGlobe /> Live
              </a>
            </div>
          </div>
        ))}
        <div className="project-card coming-soon">
          <h3><Typewriter
          words={['Em Breve...']}
          loop={true}
          cursor
          cursorStyle='_'
          typeSpeed={180}
          deleteSpeed={50}
          delaySpeed={1000}
        /></h3>
        </div>
      </div>
    </section>
  );
};

export default Projects;
